/**
 * This Store(Redux) will include the initial state and reducer.
 */
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./reducers/auth/authSlice";
import homeSlice from "./reducers/home/homeSlice";

/**
 * the redux take mimimun one reducer
 */

const store = configureStore({
    reducer: {
        auth: authSlice,
        home: homeSlice
    },

})
export default store;
