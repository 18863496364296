import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialstate';

export const homeSlice = createSlice({
  name: 'home',
  initialState: initialState,
  reducers: {
    setCategoryId: (state, action) => {
      state.selectedCategoryId = action.payload
    },
    setAllItems: (state, action) => {
      state.all_items = action.payload
    },
  },
},
)

export const { setAllItems, setCategoryId } = homeSlice.actions;

export default homeSlice.reducer



