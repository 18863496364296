import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

const LoadingPage = React.lazy(() => import('./components/LoadingPage'));

const FooterLayout = React.lazy(() => import('./layout/FooterLayout'));

const AppLayout = React.lazy(() => import('./layout/AppLayout'));

const HomeLayout = React.lazy(() => import('./layout/HomeLayout'));

const CategoryPage = React.lazy(() => import('./pages/category'));

const ItemPage = React.lazy(() => import('./pages/ItemPage'));

const OfferPage = React.lazy(() => import('./pages/Offer'));


export default function App() {

  return (
    <>
      <ToastContainer />
      <HashRouter>
        <Suspense fallback={<LoadingPage />}>
          <Routes>

            <Route Component={FooterLayout}>
              <Route element={<HomeLayout />} >
                <Route path="/category/:id" Component={CategoryPage} />
                <Route path="/" Component={CategoryPage} />
              </Route>
            </Route>
            <Route Component={AppLayout}>
              <Route path="/item/:id" element={<ItemPage />} />
              <Route path='/offers/details/:id' element={<OfferPage />} />
            </Route>
            <Route path='/admin' Component={() => {
              window.location.href = "https://al-zawak.com/dashbord";
              return null
            }}>
            </Route>
          </Routes>
        </Suspense>

      </HashRouter>
    </>
  );
}
