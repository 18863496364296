import ItemModel from "../../../model/ItemModel";

type HomeStateType = {
    selectedCategoryId: number | undefined,
    all_items: ItemModel[]
}
const initialState: HomeStateType = {
    selectedCategoryId: undefined,
    all_items: []
};
export { initialState };
export type { HomeStateType };
