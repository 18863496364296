import { createSlice } from '@reduxjs/toolkit'
// import { initialState } from './initialstate';
import MyCookies from '../../../hooks/MyCookies';
import MY_COOKIES_FILEDS from '../../../hooks/MyCookies/fileds';
import UserModel from '../../../model/UserModel';

const myCookies = MyCookies();

export type AuthStateType = {
  user: UserModel | null,
  isAuthenticated: boolean,
}

const initialState: AuthStateType = {
  user: null,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginUser(state, action) {
      console.log(action.payload)
      state.user = action.payload.user;
      state.isAuthenticated = true;
      myCookies.setUser(action.payload.user)
      myCookies.setToken(action.payload.token)
    },
    setUser(state, action) {
      state.user = action.payload;
      myCookies.setUser(action.payload)
    },
    logoutUser(state) {
      state.user = null;
      state.isAuthenticated = false;
      myCookies.removeAll([MY_COOKIES_FILEDS.TOKEN, MY_COOKIES_FILEDS.USER])
    },
  },
},
)
export const { loginUser, logoutUser, setUser } = authSlice.actions;

export default authSlice.reducer



